import React from 'react';
import { Routes, Route } from "react-router-dom";
import EquipmentList from "./equipmentlist";
import EquipmentDetail from "./equipmentdetail.component";

function Equipment(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<EquipmentList />} />
        <Route path=":equipmentId" element={<EquipmentDetail />} />
      </Routes>
    </React.Fragment>
  );
}

export default Equipment;
