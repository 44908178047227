import React, { useState, useContext } from "react";
import { Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from '../services/UserContext';
import { useNavigate } from "react-router-dom";
import ModalBase from "./modalbase.component";
import { DialogButtonBox } from "./common";

const AccountDialog = (props) => {
  console.log(props.user);
  return (
    <ModalBase title="Account" className="w-1/4" onClose={props.onClose} >
      <p className="p-6">Logged in as: {props.user.username}</p>
         <DialogButtonBox onCancel={props.onClose} onSubmit={props.onLogoff} submitText={'Uitloggen'}/>

    </ModalBase>
  );
};

function Nav() {
  const [isShowAccountDialog, setIsShowAccountDialog] = useState(false);
  const { logout, user } = useContext(UserContext);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {isShowAccountDialog && <AccountDialog onClose={() => setIsShowAccountDialog(false)} onLogoff={logout} user={user}/>}
      <nav className="bg-gray-200">
        <div className="">
          <div className="flex items-center justify-between ">
            <div className="flex-1 flex items-center">
              <div className="flex-1">
                <div className="p-1 md:p-2 flex gap-1 md:gap-2">
                  <button
                    onClick={() => navigate("/")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2,22 v-10 l10,-10 l10,10 v10 h-20"
                      />
                    </svg>
                    <span className="hidden lg:block">Dashboard</span>
                  </button>
                  {(user.isAdmin || user.isCustomer) && <button
                    onClick={() => navigate("/customers")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        >
                        <path className="text-blue-100"
                          d="M 5 18 h 2 m 3 0 h 5 m 3 0 h 2 M 5 14 h 2 m 3 0 h 5 m 3 0 h 2 M 10 10 h 5 M 10 6 h 5"
                        />
                        <path
                          d="M 2 22 v -10 a 4 4 0 0 1 2 -2 h 3 M 2 22 h 21 M 7 22 v -18 a 4 4 0 0 1 2 -2 h 7 a 4 4 0 0 1 2 2 v 18 M 23 22 v -10 a 4 4 0 0 1 -2 -2 h -2"
                        />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Klanten</span>
                  </button>}
                  <button
                    onClick={() => navigate("/sites")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M 12 23 l -4.5 -12 a 5.3 5.3 0 1 1 9 0 l -4.5 12"
                      />
                    </svg>
                    <span className="hidden lg:block">Locaties</span>
                  </button>
                  <button
                    onClick={() => navigate("/equipment")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M 6,8 l 14,-7 l 4,8 l-14,7 l -4,-8" />
                        <path d="M 17,13 l 3,6 l 3,-3 v 6 l -3,-3" />
                        <path d="M 8,11 l -2,4 l -2,-4 l 2,0" />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Apparatuur</span>
                  </button>
                  {user.isAdmin && <button
                    onClick={() => navigate("/parts")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M  4 2 h 4 a 4 4 0 0 1 2 2 v 4 a 4 4 0 0 1 -2 2 h -4 a 4 4 0 0 1 -2 -2 v -4 a 4 4 0 0 1 2 -2" />
                        <path d="M 15 2 h 4 a 4 4 0 0 1 2 2 v 4 a 4 4 0 0 1 -2 2 h -4 a 4 4 0 0 1 -2 -2 v -4 a 4 4 0 0 1 2 -2" />
                        <path d="M  4 13 h 4 a 4 4 0 0 1 2 2 v 4 a 4 4 0 0 1 -2 2 h -4 a 4 4 0 0 1 -2 -2 v -4 a 4 4 0 0 1 2 -2" />
                        <path d="M 15 13 h 4 a 4 4 0 0 1 2 2 v 4 a 4 4 0 0 1 -2 2 h -4 a 4 4 0 0 1 -2 -2 v -4 a 4 4 0 0 1 2 -2" />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Onderdelen</span>
                  </button>}
                  {user.isAdmin && <button
                    onClick={() => navigate("/users")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2 fill-blue-300 hover:fill-blue-700"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        >
                        <path
                          d="M 9 15 a 6 6 0 0 1 12 0 h -12 m 2 -10 a 4 4 0 0 1 8 0 a 4 4 0 0 1 -8 0"
                        />
                      <path strokeWidth="0"
                          d="M 2 24 v -2 a 8 8 0 0 1 16 0 v 2 h -16 m 2 -12 a 6 6 0 0 1 12 0 a 6 6 0 0 1 -12 0"
                        />
                        <path
                          d="M 4 22 a 6 6 0 0 1 12 0 h -12 m 2 -10 a 4 4 0 0 1 8 0 a 4 4 0 0 1 -8 0"
                        />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Gebruikers</span>
                  </button>}
                  {user.isAdmin && <button
                    onClick={() => navigate("/reports")}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        >
                        <path className="text-blue-100"
                          d="M 7 18 h 9 m -9 -4 h 9 m -9 -4 h 9 m -9 -4 h 9"
                        />
                        <path
                          d="M 5 2 h 13 a 4 4 0 0 1 2 2 v 16 a 4 4 0 0 1 -2 2 h -13 a 4 4 0 0 1 -2 -2 v -16 a 4 4 0 0 1 2 -2"
                        />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Reports</span>
                  </button>}
                  <span className="flex-1"></span>
                  <button
                    onClick={() => setIsShowAccountDialog(true)}
                    type="button"
                    className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white flex gap-2"
                  >
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        >
                        <path
                          d="M 4 22 a 8.5 8.5 0 0 1 17 0 h -17 m 3 -16 a 5 5 0 0 1 10 0 a 5 5 0 0 1 -10 0"
                        />
                      </g>
                    </svg>
                    <span className="hidden lg:block">Account</span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>


      </nav>
    </React.Fragment>
  );
}

export default Nav;
