import React from 'react';
import { Routes, Route } from "react-router-dom";
import PartsList from "./list.component";
import PartDetail from "./detail.component";

function Parts(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<PartsList />} />
        <Route path=":partId" element={<PartDetail />} />
      </Routes>
    </React.Fragment>
  );
}

export default Parts;
