import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/users";

class UserService {
  getAll() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL, data, { headers: authHeader() });
  }

  delete(Id) {
    return axios.delete(API_URL + '/' + Id, { headers: authHeader() });
  }

  get(Id) {
    return axios.get(API_URL + '/' + Id, { headers: authHeader() });
  }

  update(Id, data) {
    return axios.post(API_URL + '/' + Id, data, { headers: authHeader() });
  }

  findByName(name) {
    return axios.post(API_URL + '/find', { name: name }, { headers: authHeader() });
  }
}

export default new UserService();
