import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/sites";

class SiteService {
  getAll() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  get(Id) {
    return axios.get(API_URL + '/' + Id, { headers: authHeader() });
  }

  delete(Id) {
    return axios.delete(API_URL + '/' + Id, { headers: authHeader() });
  }

  getReservations(Id) {
    return axios.get(API_URL + '/' + Id + '/reservations', { headers: authHeader() });
  }

  update(Id, data) {
    return axios.post(API_URL + '/' + Id, data, { headers: authHeader() });
  }

  updateReservation(Id, data) {
    return axios.post(API_URL + '/' + Id + '/reservations', data, { headers: authHeader() });
  }

  /*deleteReservation(Id, data) {
    return axios.delete(API_URL + '/' + Id + '/reservations', data, { headers: authHeader() });
  }*/

  findByName(name) {
    return axios.post(API_URL + '/find', { name: name }, { headers: authHeader() });
  }

  alarmform(Id, data) {
    return axios.post(API_URL + '/' + Id + '/alarmform', data, { headers: authHeader() });
  }
}

export default new SiteService();
