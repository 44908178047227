import React, { useState, useEffect, useCallback } from "react";

import { useForm } from "react-hook-form";
import EventBus from "../../common/EventBus";
import ModalBase from "../modalbase.component";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import EquipmentService from "../../services/equipment.service";
import { processReservations, DialogButtonBox } from "../common";
import { serviceCallWrapper } from '../common';

const ReservationsDialog = (props) => {
  const reservationId = props.data?._id || null;
  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      equipment: props.data?.equipment?._id || props.data?.equipment,
      state: props.data?.state || 'planned',
      site: props.data?.site?._id
    }
  });
  const isEdit = props.data ? true : false;
  const [ equipment, setEquipment ] = useState([]);
  const [ reservations, setReservations ] = useState([]);
  const [ overlap, setOverlap ] = useState(false);
  const [ state, setState ] = useState([ {
    startDate: props.data?.startdate || new Date(),
    endDate: props.data?.enddate || new Date(),
    key: 'selection'
  } ]);

  useEffect(() => {
    if (props.fixedEquipment) {
      //setReservations(processReservations(props.reservations));
    } else {
      serviceCallWrapper(EquipmentService.getAll(), data => {
        setEquipment(data.equipment);
      });
    }
  }, []);

  useEffect(() => {
    const EquipmentId = watch("equipment");
    if (EquipmentId) {
      serviceCallWrapper(EquipmentService.getReservations(EquipmentId), data => {
        setReservations(processReservations(data.reservations));
      });
    }
  }, [ watch("equipment") ]);

  useEffect(props.fixedEquipment ? () => {} : () => {
    const id_list = equipment.map(e => { return e._id; });
    if (props.data) {
      if (id_list.includes(props.data?.equipment?._id)) {
        setValue("equipment", props.data?.equipment?._id);
      }
    } else {
      if (equipment.length > 0) {
        setValue("equipment", id_list[0]);
      }
    }
  }, [ equipment ]);

  const onSubmit = useCallback((data) => {
    const newData = { ...data, startdate: state[0].startDate, enddate: state[0].endDate };
    props.onSubmit(newData);
  }, [ state, props ]);

  const checkDisabledDay = useCallback((day) => {
    return reservations.map((e) => {
      return e._id !== reservationId && day >= e.startdate && day <= e.enddate;
    }).includes(true);
  }, [ reservations, reservationId ]);

  useEffect(() => {
    setOverlap(reservations.map((e) => {
      return e._id !== reservationId && state[0].startDate <= e.enddate && e.startdate <= state[0].endDate;
    }).includes(true));
  }, [ state[0], reservationId, reservations ]);

  const onDateChange = (item) => {
    setState([ item.selection ]);
  };

  return (
    <ModalBase title={isEdit ? "Reservering bewerken" : "Nieuwe reservering"} onClose={props.onClose} className="w-1/4">
      <form className="" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          {!props.fixedEquipment &&
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Apparaat
            </label>
            {!props.data || props.data?.equipment ?
            <select className="basic-select" {...register('equipment')}>
              {equipment.map((e, i) =>
                <option key={i} value={e._id}>{e.name}</option>
              )}
            </select>
            :
            <span className="basic-select">verwijderd</span>}
          </div>}
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Status
            </label>
            <select className="basic-select" {...register('state')}>
              <option value="planned">Gepland</option>
              <option value="active">Actief</option>
              <option value="finished">Afgerond</option>
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Periode
            </label>
            <DateRange
              className="w-full"
              editableDateInputs={true}
              onChange={onDateChange}
              direction="vertical"
              scroll={{ enabled: true }}
              ranges={state}
              disabledDay={(d) => checkDisabledDay(d)}
            />
            {overlap &&<p className="text-red-600">Geselecteerd bereik heeft overlap met andere reservering!</p>}
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText={isEdit ? "opslaan" : "aanmaken"}/>
      </form>
    </ModalBase>
  );
};

export default ReservationsDialog;
