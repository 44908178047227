import React, { useState, useEffect } from "react";
import { IconButton, IconButtonPencil, IconButtonBin, IconButtonReload, ButtonBox } from "../common.js";
//import { IconCollapsed, IconExpanded } from "../icons.component";
import PartService from "../../services/part.service";
import { serviceCallWrapper } from '../common';
import ConfirmDialog from "../confirmdialog.component";
import { useNavigate } from "react-router-dom";
import { PartDetail, PartIcon } from './parts/parts';

const HardwareRow = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [part, setPart] = useState(props.part);
  const [partRemove, setPartRemove] = useState(false);
  const navigate = useNavigate();

  const reloadPart = () => {
    serviceCallWrapper(PartService.get(part._id), data => {
      setPart(data.part);
    });
  };

  return (
    <div className="border rounded-xl p-2 relative group flex flex-col">
      {partRemove &&  <ConfirmDialog question={"Weet je zeker dat je dit onderdeel uit deze apparatuur wilt verwijderen?"} onConfirm={props.onRemovePart} onCancel={() => setPartRemove(false)}/>}
      <div className="flex">
        <div className="p-2"><PartIcon part={part}/></div>
        <div className="p-2">{part.name} {part.monitorstate && <span className="text-red-500">(Monitoring error: {part.monitorstate})</span>}</div>
      </div>
      <div>
        <PartDetail part={part}/>
      </div>
      <ButtonBox>
        <IconButtonReload onClick={() => reloadPart()}/>
        {props.isAdmin && <IconButtonPencil onClick={() => navigate('/parts/' + part._id)}/>}
        {props.isAdmin && <IconButtonBin onClick={() => setPartRemove(true)}/>}
      </ButtonBox>
    </div>
  );
};

export default HardwareRow;
