import React, { useState } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import { PartConfig } from '../equipment/parts/parts';

const PartConfigDialog = props => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: props.part.config || {}
  });

  return (
    <ModalBase title="Algemeen" className="max-w-4xl" onClose={props.onClose} >
      <form onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <PartConfig part={props.part} errors={errors} register={register}/>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText="opslaan"/>
      </form>
    </ModalBase>
  );
};

export default PartConfigDialog;
