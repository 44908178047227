import React, { useCallback } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const AlarmFormDialog = props => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({ defaultValues: {
    branddoormelding: false,
    testmelding: 'Direct',
    rapportage: 'Per week',
    rapportageemail: ''
  } });

  return (
    <ModalBase title="Aanmeldformulier verzenden" onClose={props.onClose} className="w-1/4">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autocomplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="branddoormelding">
              Branddoormelding
            </label>
            <input className="" id="branddoormelding" type="checkbox" {...register('branddoormelding')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="testmelding">
              Testmelding
            </label>
            <select className="basic-select" id="testmelding" {...register('testmelding')}>
              <option value="Direct">Direct</option>
              <option value="Tussen 9:00 en 21:00">Tussen 9:00 en 21:00</option>
              <option value="Na 48 uur">Na 48 uur</option>
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="rapportage">
              Rapportage
            </label>
            <select className="basic-select" id="rapportage" {...register('rapportage')}>
              <option value="Per week">Per week</option>
              <option value="Per maand">Per maand</option>
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="rapportageemail">
              Rapportage e-mail
            </label>
            <input className={`basic-input ${'rapportageemail' in errors && `border-red-500`}`} id="rapportageemail" type="text" {...register('rapportageemail', { required: true })}/>
            {errors.rapportageemail && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText="verzenden"/>
      </form>
    </ModalBase>
  );
};

export default AlarmFormDialog;
