import React, { useCallback } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const AddUserDialog = (props) => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({ defaultValues: props.data });
  const isEdit = props.data ? true : false;

  const onSelectChange = useCallback((e) => {
    const index = parseInt(e.target.value);
    if (index > -1) {
      const contact = props.customercontacts[index];
      setValue('role', contact.role);
      setValue('name', contact.name);
      setValue('office', contact.office);
      setValue('mobile', contact.mobile);
      setValue('email', contact.email);
      setValue('memo', contact.memo);
    } else {
      setValue('role', '');
      setValue('name', '');
      setValue('office', '');
      setValue('mobile', '');
      setValue('email', '');
      setValue('memo', '');
    }
  }, [props.customercontacts]);

  return (
    <ModalBase title={isEdit ? "Contact bewerken" : "Nieuw contact"} onClose={props.onClose} className="w-1/4">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autocomplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Kopieren van klantcontacten
            </label>
            <select className="basic-select" id="customercontact" onChange={onSelectChange}>
              <option value={-1}>Nieuw contact</option>)
            {props.customercontacts.map((e, i) =>
              <option value={i}>{e.name}</option>)}
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Functie
            </label>
            <input className={`basic-input ${'role' in errors && `border-red-500`}`} id="grid-name" type="text" autoFocus {...register('role', { required: true })}/>
            {errors.role && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-name">
              Naam
            </label>
            <input className={`basic-input ${'name' in errors && `border-red-500`}`} id="grid-name" type="text" {...register('name', { required: true })}/>
            {errors.name && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
          </div>
          <div className="col-span-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              Telefoon kantoor
            </label>
            <input className="basic-input" id="grid-password" type="text" {...register('office')}/>
          </div>
          <div className="col-span-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              Telefoon mobiel
            </label>
            <input className="basic-input" id="grid-password" type="text" {...register('mobile')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
              E-mail
            </label>
            <input className="basic-input" id="email" type="text" {...register('email')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-memo">
              Memo
            </label>
            <textarea className="basic-input" id="grid-memo" {...register('memo')}/>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText={isEdit ? "opslaan" : "aanmaken"}/>
      </form>
    </ModalBase>
  );
};

export default AddUserDialog;
