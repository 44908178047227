import React from "react";
import { HeaderWithButtons, IconButtonPlus, IconButtonPencil, IconButtonBin, ButtonBox } from "../common.js";
import { IconPerson } from "../icons.component";

const ContactTile = props => {
  return (
    <div className="block rounded-lg border shadow relative p-2 group">
      <IconPerson/>
      <span className="font-semibold">{props.contact.role}</span>
      <table>
        <tbody>
          <tr>
            <td className="uppercase text-sm pr-4">Naam:</td><td>{props.contact.name}</td>
          </tr>
          <tr>
            <td className="uppercase text-sm pr-4">Kantoor:</td><td><a href={`tel:${props.contact.office}`}>{props.contact.office}</a></td>
          </tr>
          <tr>
            <td className="uppercase text-sm pr-4">Mobiel:</td><td><a href={`tel:${props.contact.mobile}`}>{props.contact.mobile}</a></td>
          </tr>
          <tr>
            <td className="uppercase text-sm pr-4">E-mail:</td><td><a href={`mailto:${props.contact.email}`}>{props.contact.email}</a></td>
          </tr>
          <tr>
            <td className="uppercase text-sm align-top pr-4">Memo:</td><td>{props.contact.memo}</td>
          </tr>
        </tbody>
      </table>
      {props.editable && <ButtonBox>
        <IconButtonPencil onClick={() => props.onEdit(props.index)}/>
        <IconButtonBin onClick={() => props.onDelete(props.index)}/>
      </ButtonBox>}
    </div>
  );
};

const ContactArea = props => {
  return (
    <>
      <HeaderWithButtons text="Contacten">
        {props.editable && <IconButtonPlus onClick={() => props.onAdd()}/>}
      </HeaderWithButtons>
      <div className="sm:grid md:grid-cols-2 xl:grid-cols-3 gap-2">
      {props.contacts && props.contacts.map((contact, i) =>
        <ContactTile key={i} contact={contact} index={i} editable={props.editable} onEdit={() => props.onEdit(i)} onDelete={() => props.onDelete(i)} />
      )}
      </div>
    </>
  );
};

export default ContactArea;
