import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/customers";

class CustomerService {
  getAll() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL, data, { headers: authHeader() });
  }

  delete(customerId) {
    return axios.delete(API_URL + '/' + customerId, { headers: authHeader() });
  }

  get(customerId) {
    return axios.get(API_URL + '/' + customerId, { headers: authHeader() });
  }

  getSitesByCustomer(customerId) {
    return axios.get(API_URL + '/' + customerId + '/sites', { headers: authHeader() });
  }

  addContact(customerId, newContact) {
    return axios.post(API_URL + '/' + customerId + '/contacts', newContact, { headers: authHeader() });
  }

  addSite(customerId, newSite) {
    return axios.post(API_URL + '/' + customerId + '/sites', newSite, { headers: authHeader() });
  }

  update(customerId, data) {
    return axios.post(API_URL + '/' + customerId, data, { headers: authHeader() });
  }

  findByName(name) {
    return axios.post(API_URL + '/find', { name: name }, { headers: authHeader() });
  }
}

export default new CustomerService();
