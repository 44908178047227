import React from "react";

const Detail = props => {
  return (
    <p>dummy Detail</p>
  );
};

const Config = props => {
  return (
    <p>dummy Config</p>
  );
};

const ConfigDisplay = props => {
  return (
    <p>dummy ConfigDisplay</p>
  );
};

const Icon = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">

    </svg>
  );
};

export default { Detail, Config, ConfigDisplay, Icon };
