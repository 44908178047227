import React from 'react';
import { Routes, Route } from "react-router-dom";
import SitesList from "./list.component";
import SiteDetail from "./detail.component";

function Sites(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<SitesList />} />
        <Route path=":siteId" element={<SiteDetail />} />
      </Routes>
    </React.Fragment>
  );
}

export default Sites;
