import React, { useState, useEffect, useCallback } from "react";

import CustomerService from "../../services/customer.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { IconPerson, IconMap, IconPlus } from "../icons.component";
import ConfirmDialog from "../confirmdialog.component";
import { useNavigate, useParams } from "react-router-dom";
import AddUserDialog from "./adduserdialog.js";
import AddressDialog from "./addressdialog.js";
import DeleteCustomerDialog from "./deletecustomerdialog.js";
import { HeaderWithButtons, IconButtonPencil, IconButtonBin, IconButtonPlus, ButtonBox } from "../common.js";
import SiteDetailsDialog from "../sites/details.dialog";
import { useContext } from "react";
import { UserContext } from '../../services/UserContext';
import { serviceCallWrapper } from '../common';
import ContactArea from '../common/contactarea.component';

const CustomerDetail = (props) => {
  const { customerId } = useParams();
  const [ customer, setCustomer ] = useState({});
  const [ contactEdit, setContactEdit ] = useState(-2);
  const [ contactDelete, setContactDelete ] = useState(-1);
  const [ sites, setSites ] = useState([]);
  const [ showAddSite, setShowAddSite ] = useState(false);
  const [ addressEdit, setAddressEdit ] = useState(false);
  const [ customerDelete, setCustomerDelete ] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    serviceCallWrapper(CustomerService.get(customerId), data => {
      setCustomer(data.customer);
      setSites(data.sites);
    });
  }, [ customerId ]);

  const onSubmitContact = useCallback((data) => {
    const NewContacts = [ ...customer.contacts ];
    if (contactEdit === -1) {
      NewContacts.push(data);
    } else {
      NewContacts[contactEdit] = data;
    }
    const NewCustomer = { ...customer, contacts: NewContacts };
    serviceCallWrapper(CustomerService.update(customerId, NewCustomer), data => {
      setCustomer(data.customer);
      setContactEdit(-2);
    });
  }, [ customerId, customer, contactEdit ]);

  const onSubmitAddress = useCallback((data) => {
    serviceCallWrapper(CustomerService.update(customerId, data), data => {
      setCustomer(data.customer);
      navigate("/customers/" + data.customer._id);
    });
    setAddressEdit(false);
  }, [ customerId, navigate ]);

  const onSubmitNewSite = useCallback((data) => {
    serviceCallWrapper(CustomerService.addSite(customerId, data), data => {
      navigate("/sites/" + data.site._id);
    });
  }, [ customerId ]);

  const onDeleteContact = useCallback(() => {
    customer.contacts.splice(contactDelete, 1);
    serviceCallWrapper(CustomerService.update(customerId, customer), data => {
      setContactDelete(-1);
      setCustomer(data.customer);
    });
  }, [ customerId, customer, contactDelete ]);

  const onDeleteCustomer = useCallback(() => {
    serviceCallWrapper(CustomerService.delete(customerId), data => {
      navigate("/customers");
    });
  }, [ customerId, navigate ]);

  return (
    <PageBase header={customer.name} onBin={user.isAdmin ? () => setCustomerDelete(true) : null}>
      <div>
        <h1 className="uppercase tracking-wide text-xl font-bold py-2">Adres</h1>
        <div className="sm:grid md:grid-cols-2 xl:grid-cols-3 gap-2">
          <div className="rounded-lg border shadow relative p-2 group">
            <IconMap/>
            <span className="font-semibold">Algemeen</span>
            <div className="">
              <p className="uppercase text-sm">Adres:</p>
              <div className="pl-2">
                <p>{customer.street} {customer.number}</p>
                <p>{customer.postalcode} {customer.city}</p>
                <p>{customer.country}</p>
              </div>
              <p className="uppercase text-sm">Memo:</p>
              <p className="text-xs whitespace-pre-wrap pl-2">{customer.memo}</p>
            </div>
            {user.isAdmin &&
            <ButtonBox>
              <IconButtonPencil onClick={() => setAddressEdit(true)}/>
            </ButtonBox>}
          </div>
        </div>
        <ContactArea
          contacts={customer.contacts}
          editable={user.isAdmin}
          onAdd={() => setContactEdit(-1)}
          onEdit={setContactEdit}
          onDelete={setContactDelete}/>
        <HeaderWithButtons text="Locaties">
          {user.isAdmin && <IconButtonPlus onClick={() => setShowAddSite(true)}/>}
        </HeaderWithButtons>
        <div className="grid grid-cols-1 gap-2">
        {sites && sites.map((e, i) =>
          <button className="text-left w-full border p-2 rounded-md shadow" key={i} onClick={() => navigate("/sites/"+e._id)}>
            {e.name}
          </button>
        )}
        </div>
      </div>
      {customerDelete && <DeleteCustomerDialog onClose={() => {setCustomerDelete(false);}} onDelete={onDeleteCustomer}/>}
      {contactEdit >= -1 && <AddUserDialog onClose={() => {setContactEdit(-2);}} onSubmit={onSubmitContact} data={customer.contacts[contactEdit]}/>}
      {addressEdit && <AddressDialog onClose={() => {setAddressEdit(false);}} onSubmit={onSubmitAddress} data={customer}/>}
      {showAddSite && <SiteDetailsDialog onClose={() => {setShowAddSite(false);}} onSubmit={onSubmitNewSite}/>}
      {contactDelete > -1 &&  <ConfirmDialog question={"Weet je zeker dat je het contact met de naam " + (customer.contacts?.at(contactDelete)?.name) + " wilt verwijderen?"} onConfirm={onDeleteContact} onCancel={() => setContactDelete(-1)}/>}
    </PageBase>
  );
};

export default CustomerDetail;
