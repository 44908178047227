import React from 'react';
import { Routes, Route } from "react-router-dom";
import CustomerList from "./customerlist";
import CustomerDetail from "./customerdetail.component";

function Customers(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<CustomerList />} />
        <Route path=":customerId" element={<CustomerDetail />} />
      </Routes>
    </React.Fragment>
  );
}

export default Customers;
