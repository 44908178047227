import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import PartService from "../../services/part.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { HeaderWithButtons, serviceCallWrapper, ButtonBox, IconButtonPencil } from '../common';
import { useNavigate, useParams, Link } from "react-router-dom";
import EditPartDialog from "./editpart.dialog";
import DeletePartDialog from "./delete.dialog";
import PartConfigDialog from "./config.dialog";
import { PartDetail as PartDetailInternal, PartConfigDisplay, partTypes } from '../equipment/parts/parts';
import IssueDialog from "./issue.dialog";
import IssueService from "../../services/issue.service";

const issueColor = state => {
  const colors = {
    'new': 'bg-red-500',
    'acknowledged': 'bg-red-200',
    'confirmed': 'bg-orange-200',
    'assigned': 'bg-yellow-200',
    'resolved': 'bg-green-200',
    'closed': ''
  };
  return colors[state];
};

const IssueRow = props => {
  const [ issue, setIssue ] = useState(props.issue);
  const [ isEdit, setEdit ] = useState(false);

  const onSubmit = data => {
    serviceCallWrapper(IssueService.update(issue._id, data), data => {
      setIssue(data.issue);
      setEdit(false);
    });
  };

  if ((issue.state == 'closed' && props.showClosed) || issue.state !== 'closed') {
    return (
      <div className={"rounded p-1 grid grid-cols-2 md:grid-cols-partissue items-center relative text-sm md:text-md min-h-[4em] " + issueColor(issue.state)}>
        {isEdit && <IssueDialog data={issue} onClose={() => setEdit(false)} onSubmit={onSubmit}/>}
        <p className="col-span-2 md:col-span-1 pr-14">{issue.message}</p>
        <p className="col-span-2 md:col-span-1">{issue.state}</p>
        <p>{new Date(issue.createDate).toLocaleString('nl-NL')}</p>
        <p>{issue.lastEditDate && new Date(issue.lastEditDate).toLocaleString('nl-NL')}</p>
        <div className="flex justify-end gap-2 absolute top-2 right-2">
          <IconButtonPencil type="button" onClick={() => setEdit(true)}/>
        </div>
      </div>
    );
  } else {
    return (<></>);
  }

};

const PartDetail = (props) => {
  const { partId } = useParams();
  const [ part, setPart ] = useState({});
  const [ issues, setIssues ] = useState([]);
  const [ partDelete, setPartDelete ] = useState(false);
  const [ partEdit, setPartEdit ] = useState(false);
  const navigate = useNavigate();
  const [ isConfigVisible, setConfigVisible ] = useState(false);
  const [ showClosedIssues, setShowClosedIssues ] = useState(false);

  const parsePartResponse = data => {
    setPart(data.part);
    setIssues(data.issues);
  };

  useEffect(() => {
    serviceCallWrapper(PartService.get(partId), data => {
      parsePartResponse(data);
    });
  }, [ partId ]);

  const onSubmitDetails = useCallback((data) => {
    serviceCallWrapper(PartService.update(partId, data), data => {
      parsePartResponse(data);
    });
    setPartEdit(false);
  }, [ partId ]);

  const onDeletePart = useCallback(() => {
    serviceCallWrapper(PartService.delete(partId), data => {
      navigate("/parts");
    });
  }, [ partId, navigate ]);

  const onSubmitConfig = useCallback((data) => {
    serviceCallWrapper(PartService.update(partId, { config: data }), data => {
      parsePartResponse(data);
    });
    setConfigVisible(false);
  }, [ partId ]);


  return (
    <>
    {partDelete && <DeletePartDialog onClose={() => {setPartDelete(false);}} onDelete={onDeletePart} part={part}/>}
    {partEdit && <EditPartDialog onClose={() => setPartEdit(false)} onSubmit={onSubmitDetails} data={part}/>}
    {isConfigVisible && <PartConfigDialog onClose={() => setConfigVisible(false)} onSubmit={onSubmitConfig} part={part}/>}
    <PageBase
        header={part.name}
        onBin={() => setPartDelete(true)}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 items-stretch">
        <div className="md:h-96 block rounded-lg border shadow relative p-2 group">
          <span className="font-semibold">Algemeen</span>
          <div className="">
            <p className="uppercase text-sm">apparaat:</p>
            {part.equipment && <p className="pl-2"><Link to={"/equipment/" + part.equipment?._id}>{part.equipment?.name}</Link></p>}
            <p className="uppercase text-sm">type:</p>
            <p className="pl-2">{partTypes[part.type]?.name || 'invalid'}</p>
          </div>
          <ButtonBox>
            <IconButtonPencil onClick={() => setPartEdit(true)}/>
          </ButtonBox>
        </div>

        <div className="md:block md:col-span-2 rounded-lg border shadow relative p-2 group">
          <span className="font-semibold">Configuration</span>
          <PartConfigDisplay part={part}/>
          <ButtonBox>
            <IconButtonPencil onClick={() => setConfigVisible(true)}/>
          </ButtonBox>
        </div>

        <div className="block md:col-span-3 rounded-lg border shadow p-2">
          <span className="font-semibold">Data</span>
          <PartDetailInternal part={part}/>
        </div>

        <HeaderWithButtons text="Issues" className="md:col-span-3">
          {!showClosedIssues && <button className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white w-48" onClick={() => setShowClosedIssues(true)}>show closed issues</button>}
          {showClosedIssues && <button className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white w-48" onClick={() => setShowClosedIssues(false)}>hide closed issues</button>}
        </HeaderWithButtons>
        <div className="grid grid-cols-1 block md:col-span-3 gap-1 md:gap-2">
          <div className="p-1 grid grid-cols-2 md:grid-cols-partissue text-sm xl:text-md">
            <p className="font-bold col-span-2 md:col-span-1">message</p>
            <p className="font-bold col-span-2 md:col-span-1">state</p>
            <p className="font-bold">date open</p>
            <p className="font-bold">last edit</p>
            <p></p>
          </div>
          {issues.map((issue, index) =>
            <IssueRow key={index} issue={issue} showClosed={showClosedIssues}/>
          )}
        </div>
      </div>

      </PageBase>
    </>
  );
};

export default PartDetail;
