import React, { useState, useEffect } from "react";

import SiteService from "../../services/site.service";
import PageBase from "../pagebase.component";
import { useNavigate } from "react-router-dom";
import { serviceCallWrapper } from '../common';

const Sites = (props) => {
  const [ sites, setSites ] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    serviceCallWrapper(SiteService.getAll(), data => {
      setSites(data.data);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(SiteService.findByName(text), data => {
      setSites(data.sites);
    });
  };

  const handleSearchEnter = () => {
    if (sites.length === 1) {
      navigate("/sites/" + sites[0]._id);
    }
  };

  return (
    <PageBase
        header="Locaties"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search sites"
      >
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-1 md:gap-2">
      {sites.map((site, i) =>
        <button className="text-left w-full border p-2 rounded-md shadow" key={i} onClick={() => navigate("/sites/"+site._id)}>
          {site.customer.name} &gt; {site.name}
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default Sites;
