import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useReducer } from "react";

import SiteService from "../../services/site.service";
import ReservationService from "../../services/reservation.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { IconMap, IconPerson, IconPencil, IconBin, IconPlus } from "../icons.component";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useNavigate, useParams, Link } from "react-router-dom";
import L from 'leaflet';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import AddUserDialog from "./adduser.dialog";
import SiteDetailsDialog from "./details.dialog";
import ReservationDialog from "../common/reservation.dialog";
import { HeaderWithButtons, IconButton, IconButtonPlus, IconButtonPencil, IconButtonBin, processReservation, processReservations, ButtonBox } from "../common.js";
import DeleteSiteDialog from "./delete.dialog.js";
import ConfirmDialog from "../confirmdialog.component";
import { serviceCallWrapper } from '../common';
import { UserContext } from '../../services/UserContext';
import ContactArea from '../common/contactarea.component';
import AlarmFormDialog from './alarmform.dialog';
import TimetableDialog from './timetable.dialog';
import { useTranslation } from "react-i18next";

const mapIcon = L.icon({
  iconUrl: "/map_marker.svg",
  iconSize: [ 36, 56 ],
  iconAnchor: [ 18, 56 ],
  popupAnchor:  [ 0, -56 ]
});

const DraggableMarker = (props) => {
  const [ draggable, setDraggable ] = useState(false);
  const [ position, setPosition ] = useState(props.position);
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          props.onMarkerMove(marker.getLatLng());
        }
      },
    }),
    [],
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  useEffect(() => {
    const marker = markerRef.current;
    if (marker != null) {
      marker.setLatLng(props.position);
    }
  }, [ props.position ]);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={mapIcon}>
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is sleepbaar'
            : 'Klik hier om sleepbaar te maken'}
        </span>
      </Popup>
    </Marker>
  );
};

const ReservationRow = props => {
  const [ isEdit, setEdit ] = useState(false);
  const [ isDelete, setDelete ] = useState(false);
  const [ reservation, setReservation ] = useState(props.reservation);

  const onSubmit = data => {
    serviceCallWrapper(ReservationService.update(reservation._id, data), data => {
      setReservation(processReservation(data.reservation));
      setEdit(false);
    });
  };

  return (
    <div className="rounded p-1 grid grid-cols-3 md:grid-cols-6 items-center relative text-sm md:text-md min-h-[6em] ">
      {isEdit && <ReservationDialog onClose={() => setEdit(false)} onSubmit={onSubmit} data={reservation} fixedEquipment={props.fixedEquipment}/>}
      {isDelete &&  <ConfirmDialog question={"Weet je zeker dat je deze reservering wilt verwijderen?"} onConfirm={props.onDelete} onCancel={() => setDelete(false)}/>}
      <p className="col-span-3 md:col-span-1">{reservation.equipment
        ? reservation.state === 'active' || props.isAdmin
          ? <Link to={"/equipment/"+reservation.equipment._id}>{reservation.equipment.name}</Link>
          : reservation.equipment.name
        : "(verwijderd)"}
      </p>
      <p className="md:hidden col-span-3 md:col-span-1"/>
      <p>{reservation.state_nl}</p>
      <p>{reservation.startdate.toLocaleDateString()}</p>
      <p>{reservation.enddate.toLocaleDateString()}</p>
      {props.isAdmin
      ?
      <div className="flex justify-end gap-2 absolute top-2 right-2">
        <IconButtonPencil type="button" onClick={() => setEdit(true)}/>
        <IconButtonBin onClick={() => setDelete(true)}/>
      </div>
      :
      <p></p>}
    </div>
  );
};

const TimetableRow = props => {
  const { t, i18n } = useTranslation();
  const mandatory = props.timetable[props.day]?.mandatory || false;
  const disarm = props.timetable[props.day]?.disarm || '-';
  const arm = props.timetable[props.day]?.arm || '-';

  return (
    <React.Fragment>
      <div className="hidden md:grid grid-cols-timetable mb-2">
        <span>{t(props.day)}</span>
        <span className="w-full text-center">{mandatory ? t('must') : t('may')}</span>
        <span>{t('disarm at')}</span>
        <span className="w-full text-center">{disarm}</span>
        <span>{t('hour / must arm at')}</span>
        <span className="w-full text-center">{arm}</span>
        <span>{t('hour')}</span>
      </div>
      <div className="grid md:hidden grid-cols-2 mb-2">
        <span>{t(props.day)}</span>
        <span className="w-full text-center"></span>
        <div className="col-span-2 grid grid-cols-timetable-small">
          <span>{mandatory ? t('must') : t('may')} {t('disarm at')}</span>
          <span className="w-full text-center">{disarm}</span>
          <span className="">{t('hour')}</span>
          <span className="">{t('must arm at')}</span>
          <span className="w-full text-center">{arm}</span>
          <span>{t('hour')}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

const SiteDetail = (props) => {
  const { siteId } = useParams();
  const [ site, setSite ] = useState({});
  const [ reservations, setReservations ] = useState([]);
  const [ position, setPosition ] = useState([ 53.2137283, 5.8508733 ]);
  const [ map, setmap ] = useState(null);
  const navigate = useNavigate();
  const [ reservationAdd, setReservationAdd ] = useState(false);
  const [ siteEdit, setSiteEdit ] = useState(false);
  const [ siteDelete, setSiteDelete ] = useState(false);
  const [ contactEdit, setContactEdit ] = useState(-2);
  const [ contactDelete, setContactDelete ] = useState(-1);
  const [ reservationDelete, setReservationDelete ] = useState(-1);
  const { user } = useContext(UserContext);
  const [ isAlarmFormVisible, setAlarmFormVisible ] = useState(false);
  const [ isTimetableEdit, setTimetableEdit ] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    serviceCallWrapper(SiteService.get(siteId), data => {
      setSite(data.site);
      setReservations(processReservations(data.reservations));
      if (data.site) {
        if (data.site.latitude && data.site.longitude && !isNaN(data.site.latitude) && !isNaN(data.site.longitude)) {
          setPosition([ data.site.latitude, data.site.longitude ]);
        }
      }
    });
  }, [ siteId ]);

  useEffect(() => {
    if (map) {
      map.panTo(position);
    }
  }, [ map, position ]);

  const onMarkerMove = useCallback(({ lat, lng }) => {
    const data = { latitude: lat, longitude: lng };
    serviceCallWrapper(SiteService.update(siteId, data), data => {
      setSite(data.site);
    });
  }, [ siteId, site ]);

  const onAddReservation = useCallback((data) => {
    data.site = siteId;
    serviceCallWrapper(ReservationService.add(data), data => {
      serviceCallWrapper(SiteService.getReservations(siteId), data => {
        setReservations(processReservations(data.reservations));
        setReservationAdd(false);
      });
    });
  }, [ siteId ]);

  const onSubmitDetails = useCallback((data) => {
    serviceCallWrapper(SiteService.update(siteId, data), data => {
      setSite(data.site);
      setSiteEdit(false);
    });
  }, [ siteId ]);

  const onSubmitContact = useCallback((data) => {
    if (contactEdit === -1) {
      site.contacts.push(data);
    } else {
      site.contacts[contactEdit] = data;
    }
    serviceCallWrapper(SiteService.update(siteId, site), data => {
      setSite(data.site);
      setContactEdit(-2);
    });
  }, [ siteId, site, contactEdit ]);

  const onDeleteSite = useCallback(() => {
    serviceCallWrapper(SiteService.delete(siteId), data => {
      navigate("/customers/" + site.customer?._id);
    });
  }, [ siteId, navigate, site ]);

  const onDeleteContact = useCallback(() => {
    site.contacts.splice(contactDelete, 1);
    serviceCallWrapper(SiteService.update(siteId, site), data => {
      setContactDelete(-1);
      setSite(data.site);
    });
  }, [ siteId, site, contactDelete ]);

  const onDeleteReservation = reservationId => {
    serviceCallWrapper(ReservationService.delete(reservationId), data => {
      serviceCallWrapper(SiteService.getReservations(siteId), data => {
        setReservations(processReservations(data.reservations));
      });
    });
  };

  const onSubmitAlarmForm = data => {
    serviceCallWrapper(SiteService.alarmform(siteId, data), data => {
      setAlarmFormVisible(false);
    });
  };

  const onSubmitTimetable = data => {
    serviceCallWrapper(SiteService.update(siteId, { timetable: data }), data => {
      setSite(data.site);
    });
    setTimetableEdit(false);
  };

  return (
    <React.Fragment>
    {contactDelete > -1 &&  <ConfirmDialog question={"Weet je zeker dat je het contact met de naam " + (site.contacts?.at(contactDelete)?.name) + " wilt verwijderen?"} onConfirm={onDeleteContact} onCancel={() => setContactDelete(-1)}/>}
    {siteDelete && <DeleteSiteDialog onClose={() => {setSiteDelete(false);}} onDelete={onDeleteSite}/>}
    {reservationAdd && <ReservationDialog onClose={() => {setReservationAdd(false);}} onSubmit={onAddReservation}/>}
    {siteEdit && <SiteDetailsDialog onClose={() => setSiteEdit(false)} onSubmit={onSubmitDetails} data={site}/>}
    {contactEdit >= -1 &&<AddUserDialog onClose={() => {setContactEdit(-2);}} onSubmit={onSubmitContact} data={site.contacts.at(contactEdit)} customercontacts={site.customer.contacts}/>}
    {isAlarmFormVisible && <AlarmFormDialog site={site} onClose={() => {setAlarmFormVisible(false);}} onSubmit={onSubmitAlarmForm}/>}
    {isTimetableEdit && <TimetableDialog timetable={site.timetable} onClose={() => {setTimetableEdit(false);}} onSubmit={onSubmitTimetable}/>}
    <PageBase
        header={site.name}
        onBin={user.isAdmin ? () => setSiteDelete(true) : null}>
      <div className="grid sm:grid-cols-3 gap-2 items-stretch">
        <div className="block rounded-lg border shadow relative p-2 group">
          <IconMap/>
          <span className="font-semibold">Algemeen</span>
          <div className="">
            <p className="uppercase text-sm">Klant:</p>
            <p className="pl-2">{(user.isAdmin || user.isCustomer) ? <Link to={"/customers/" + site.customer?._id}>{site.customer?.name}</Link> : site.customer?.name}</p>
            <p className="uppercase text-sm">Adres:</p>
            <p className="pl-2">{site.street} {site.number}</p>
            <p className="uppercase text-sm"></p>
            <p className="pl-2">{site.postalcode} {site.city}</p>
            <p className="uppercase text-sm"></p>
            <p className="pl-2">{site.country}</p>
            <p className="uppercase text-sm">Latitude:</p>
            <p className="pl-2">{site.latitude ? site.latitude.toFixed(5) : '-'}</p>
            <p className="uppercase text-sm">Longitude:</p>
            <p className="pl-2">{site.longitude ? site.longitude.toFixed(5) : '-'}</p>
            <p className="uppercase text-sm">Prom:</p>
            <p className="pl-2">{site.promnumber ? site.promnumber : '-'}</p>
            <p className="uppercase text-sm">Objectsoort:</p>
            <p className="pl-2">{site.objecttype ? site.objecttype : '-'}</p>
          </div>
          {user.isAdmin &&
          <ButtonBox>
            <IconButtonPencil onClick={() => setSiteEdit(true)}/>
          </ButtonBox>}
        </div>

        <div className="h-96 sm:col-span-2 rounded-lg border shadow">
        <MapContainer center={position} zoom={13} scrollWheelZoom={true} className="h-full" whenCreated={setmap}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        {user.isAdmin
          ? <DraggableMarker position={position} onMarkerMove={(data) => onMarkerMove(data)}/>
          : <Marker
            position={position}
            icon={mapIcon}>
          </Marker>
        }
        </MapContainer>
        </div>
        <div className="sm:col-span-2 block rounded-lg border shadow relative p-2 group">
          <IconMap/>
          <span className="font-semibold">{t('timetable')}</span>
          <TimetableRow day="monday" timetable={site.timetable || {}}/>
          <TimetableRow day="tuesday" timetable={site.timetable || {}}/>
          <TimetableRow day="wednesday" timetable={site.timetable || {}}/>
          <TimetableRow day="thursday" timetable={site.timetable || {}}/>
          <TimetableRow day="friday" timetable={site.timetable || {}}/>
          <TimetableRow day="saturday" timetable={site.timetable || {}}/>
          <TimetableRow day="sunday" timetable={site.timetable || {}}/>
          {user.isAdmin &&
          <ButtonBox>
            <IconButtonPencil onClick={() => setTimetableEdit(true)}/>
          </ButtonBox>}
        </div>
        <div className="block rounded-lg border shadow relative p-2 group">
          <IconMap/>
          <span className="font-semibold">{t('actions')}</span>
          <div>
            <button type="button" onClick={() => setAlarmFormVisible(true)} className="basic-button-blue w-full">
              Aanmeldformulier verzenden
            </button>
          </div>
        </div>
      </div>
      <ContactArea
        contacts={site.contacts}
        editable={user.isAdmin}
        onAdd={() => setContactEdit(-1)}
        onEdit={setContactEdit}
        onDelete={setContactDelete}/>
      <HeaderWithButtons text="Reserveringen">
        {user.isAdmin && <IconButtonPlus onClick={() => setReservationAdd(true)}/>}
      </HeaderWithButtons>
      <div className="grid grid-cols-1 block md:col-span-3 gap-1 md:gap-2 striped">
        <div className="p-1 grid grid-cols-3 md:grid-cols-6 items-center gap-2 text-sm xl:text-md">
          <p className="font-bold col-span-3 md:col-span-1">Apparaat</p>
          <p className="font-bold">Status</p>
          <p className="font-bold">Startdatum</p>
          <p className="font-bold">Einddatum</p>
          <p></p>
        </div>
        {reservations.map((reservation, i) =>
          <ReservationRow
            key={i}
            reservation={reservation}
            fixedEquipment={false}
            isAdmin={user.isAdmin}
            onDelete={() => onDeleteReservation(reservation._id)}/>
        )}
        </div>
      </PageBase>
    </React.Fragment>
  );
};

export default SiteDetail;
