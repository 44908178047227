import React from "react";

const ModalBase = (props) => {
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-1002 outline-none focus:outline-none"
      >
        <div className={"relative w-auto my-6 mx-auto max-w-3xl " + props.className}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            {props.title && <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                {props.title}
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={props.onClose}
              >
                <span className="bg-transparent text-gray-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>}
            {/*body*/}
            {props.children}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-1001 bg-black"></div>
    </>
);
};

ModalBase.defaultProps = {

};

export default ModalBase;
