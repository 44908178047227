import React, { useState, useEffect } from "react";

import UserService from "../../services/user.service";
import PageBase from "../pagebase.component";
import { useNavigate } from "react-router-dom";
import { serviceCallWrapper } from '../common';
import UserDetailsDialog from './details.dialog';

const Users = (props) => {
  const [ users, setUsers ] = useState([]);
  const [ isShowAddUser, setShowAddUser ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    serviceCallWrapper(UserService.getAll(), data => {
      setUsers(data.users);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(UserService.findByName(text), data => {
      setUsers(data.users);
    }).catch(error => {

    });
  };

  const handleSearchEnter = () => {
    if (users.length === 1) {
      navigate("/users/" + users[0]._id);
    }
  };

  const onAddUser = newUser => {
    navigate("/users/" + newUser._id);
  };

  return (
    <PageBase
        header="Gebruikers"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search users"
        onAdd={() => setShowAddUser(true)}>
      {isShowAddUser && <UserDetailsDialog onClose={() => setShowAddUser(false)} onSubmit={onAddUser} />}
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-1 md:gap-2">
      {users.map((user, i) =>
        <button className="text-left w-full border p-2 rounded-md shadow" key={i} onClick={() => navigate("/users/"+user._id)}>
          {user.username}
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default Users;
