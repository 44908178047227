import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import { serviceCallWrapper } from '../common';
import PartService from "../../services/part.service";
import { partTypes } from '../equipment/parts/parts';

const EditPartDialog = (props) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      part: props.data?._id
    }
  });
  const [ parts, setParts ] = useState([]);

  useEffect(() => {
    serviceCallWrapper(PartService.getAllFree(), data => {
      setParts(data.parts);
    });
  }, []);

  useEffect(() => {
    const partId = watch("part");
    if (parts.length > 0 && !partId) {
      setValue("part", parts[0]._id);
    }
  }, [ parts ]);

  const onTypeChange = data => {
    const type = data.target.value;
    serviceCallWrapper(PartService.getAllFree({ type }), data => {
      setParts(data.parts);
    });
  };

  return (
    <ModalBase title="Aanpassen" onClose={props.onClose} className="w-1/4">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2 items-end">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Type
            </label>
            <select className="basic-select" onChange={onTypeChange}>
              <option value="">Alle</option>
            {Object.keys(partTypes).map((key) =>
              <option value={key}>{partTypes[key].name}</option>)}
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Onderdeel
            </label>
            <select className="basic-select" {...register('part', { required: true })}>
            {parts.map((part) =>
              <option value={part._id}>{part.name}</option>)}
            </select>
          </div>

        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit/>
      </form>
    </ModalBase>
  );
};

export default EditPartDialog;
