import React, { useState, useEffect, useCallback } from "react";

import { useForm } from "react-hook-form";
import EquipmentService from "../../services/equipment.service";
import PartService from "../../services/part.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { IconMap, IconReload, IconCollapsed, IconExpanded } from "../icons.component";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useNavigate, useParams, Link } from "react-router-dom";
import L from 'leaflet';
import ModalBase from "../modalbase.component";
import { HeaderWithButtons, IconButton, IconButtonPlus, IconButtonPencil, IconButtonBin, IconButtonReload, processReservations, ButtonBox, DialogButtonBox } from "../common.js";
import ConfirmDialog from "../confirmdialog.component";
import HardwareRow from './hardwarerow';

const EquipmentDetailsDialog = (props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: props.data?.name || '',
      memo: props.data?.memo || '',
      state: props.data?.state || 'inactive'
    }
  });

  return (
    <ModalBase title="Algemeen" onClose={props.onClose} className="w-1/3">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2 items-end">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
              Apparaatnaam
            </label>
            <input className="basic-input" type="text" {...register('name')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="state">
              State
            </label>
            <select className="basic-select" {...register('state')}>
              <option value="inactive">Inactive</option>)}
              <option value="active">Active</option>)}
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="memo">
              Memo
            </label>
            <textarea className="basic-input" id="grid-memo" {...register('memo')}/>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit/>
      </form>
    </ModalBase>
  );
};

export default EquipmentDetailsDialog;
