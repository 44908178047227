export const TRANSLATIONS_EN = {
  "monday": "monday",
  "tuesday": "tuesday",
  "wednesday": "wednesday",
  "thursday": "thursday",
  "friday": "friday",
  "saturday": "saturday",
  "sunday": "sunday",
  "tuesday": "tuesday"
};
