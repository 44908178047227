import React, { useState } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import UserService from "../../services/user.service";
import { serviceCallWrapper } from '../common';
import { useNavigate } from "react-router-dom";

const UserDetailsDialog = props => {
  const [ errorMessage, setErrorMessage ] = useState();
  const [ responseErrors, setResponseErrors ] = useState({});
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: props.data?.username,
      forcePasswordReset: props.data?.forcePasswordReset
    }
  });
  const navigate = useNavigate();

  const onSubmitDetails = postedData => {
    if (props.data) {
      // existing user
      if (!postedData.password) {
        delete(postedData.password);
      }
      serviceCallWrapper(UserService.update(props.data._id, postedData), data => {
        props.onSubmit(data.user);
      }, error => {
        if (error.response?.status === 504) {
          setResponseErrors(error.response.data);
        }
      });
    } else {
      // new user
      serviceCallWrapper(UserService.add(postedData), data => {
        navigate("/users/" + data.user._id);
      }, error => {
        if (error.response?.status === 504) {
          setResponseErrors(error.response.data);
        }
      });
    }

  };

  const handleUsernameChange = data => {
    delete responseErrors.username;
    setResponseErrors({ ...responseErrors });
  };

  return (
    <ModalBase title="Algemeen" className="w-1/4" onClose={props.onClose} >
      <form onSubmit={handleSubmit(onSubmitDetails)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          {errorMessage && <div className="col-span-4 bg-red-200 p-2">
            {errorMessage}
          </div>}
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
              Gebruikersnaam
            </label>
            <input className={`basic-input ${('username' in errors || 'username' in responseErrors) && `border-red-500`}`} type="text" autocomplete="new-password" id="username" autofill="off" autoFocus {...register('username', { required: true })} onChange={handleUsernameChange} />
            {errors.username && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
            {responseErrors.username && <p className="text-red-500 text-xs italic mb-2">{responseErrors.username}</p>}
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="password">
              Wachtwoord
            </label>
            <input className={`basic-input ${'password' in errors && `border-red-500`}`} type="password" id="password" placeholder="" autocomplete="new-password" {...register('password', { required: !('data' in props) })}/>
            {errors.password && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="forcereset">
              Reset bij login verplicht
            </label>
            <input className="" type="checkbox" id="forcereset" {...register('forcePasswordReset')}/>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText={props.data ? 'Opslaan' : 'Aanmaken'}/>
      </form>
    </ModalBase>
  );
};

export default UserDetailsDialog;
