import React, { useState, useEffect } from "react";
import PageBase from "./pagebase.component";
import { HeaderWithButtons, serviceCallWrapper } from './common';
import IssueService from "../services/issue.service";

const issueColor = state => {
  const colors = {
    'new': 'bg-red-500',
    'acknowledged': 'bg-red-200',
    'confirmed': 'bg-orange-200',
    'assigned': 'bg-yellow-200',
    'resolved': 'bg-green-200',
    'closed': ''
  };
  return colors[state];
};

const Dashboard = (props) => {
  const [issueCount, setIssueCount] = useState({ 'new': 0, 'acknowledged': 0, 'confirmed': 0, 'assigned': 0 });

  useEffect(() => {
    serviceCallWrapper(IssueService.getCounts(), data => {
      let dictionary = Object.assign({}, ...data.parts.map((x) => ({ [x._id]: x.count })));
      setIssueCount(prev => ({ ...prev, ...dictionary }));
    });
  }, []);

  return (
    <PageBase header="Issues">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-1 md:gap">
        <div className={"border rounded py-3 text-center " + issueColor('new')}>
          <p className="">New</p>
          <p className="text-2xl p-3">{issueCount.new}</p>
        </div>
        <div className={"border rounded py-3 text-center " + issueColor('acknowledged')}>
          <p className="">Acknowledged</p>
          <p className="text-2xl p-3">{issueCount.acknowledged}</p>
        </div>
        <div className={"border rounded py-3 text-center " + issueColor('confirmed')}>
          <p className="">Confirmed</p>
          <p className="text-2xl p-3">{issueCount.confirmed}</p>
        </div>
        <div className={"border rounded py-3 text-center " + issueColor('assigned')}>
          <p className="">Assigned</p>
          <p className="text-2xl p-3">{issueCount.assigned}</p>
        </div>
      </div>
    </PageBase>
  );
};

export default Dashboard;
