import React from 'react';
import {
  Routes,
  Route,
  Outlet
} from 'react-router-dom';
import './App.css';

import Customers from './components/customers/customers';
import BoardAdmin from './components/board-admin.component';
import Nav from './components/nav.component';
import Sites from './components/sites/sites';
import Dashboard from './components/dashboard.component.js';
import Equipment from './components/equipment/equipment';
import Parts from './components/parts/parts';
import Users from './components/users/users';
import ChangePassword from "./components/changepassword.component";

function AuthApp() {
  return (
    <div className="">
      <Nav/>
        <Routes>
          <Route path="/" element={<PageBase/>} >
            <Route path="/" element={<Dashboard/>} />
            <Route path="/customers/*" element={<Customers/>} />
            <Route path="/sites/*" element={<Sites/>}/>
            <Route path="/equipment/*" element={<Equipment/>} />
            <Route path="/parts/*" element={<Parts/>} />
            <Route path="/users/*" element={<Users/>} />
            <Route path="/admin" element={<BoardAdmin/>} />
            <Route path="/changepassword" element={<ChangePassword/>} />
          </Route>
        </Routes>
      </div>
  );
}

const PageBase = props => {
  return (
    <React.Fragment>
      <header/>
      <main className="bg-white p-1 md:p-2 mx">
        <Outlet />
      </main>
    </React.Fragment>
  );
};

export default AuthApp;
