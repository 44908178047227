import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { UserProvider } from './services/UserContext';
//import i18n from "i18next";
//import { initReactI18next } from "react-i18next";
//import Backend from 'i18next-http-backend';

//import reportWebVitals from './reportWebVitals';
//import * as serviceWorker from "./serviceWorker";

import "./translations/i18n";

ReactDOM.render(
	<BrowserRouter>
		<UserProvider>
      <App />
		</UserProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
