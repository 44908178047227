import React from "react";
import ModalBase from "./modalbase.component";
import { DialogButtonBox } from "./common";

const ConfirmDialog = (props) => {
  return (
    <ModalBase onClose={props.onClose} className="w-1/4">
      <div className="p-6">
        <p>{props.question}</p>
      </div>
      <DialogButtonBox onCancel={props.onCancel} onDelete={props.onConfirm} deleteText={props.textyes}/>
    </ModalBase>
  );
};

ConfirmDialog.defaultProps = {
  question: "No Question",
  textyes: "ja"
};

export default ConfirmDialog;
