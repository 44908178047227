import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";

import { UserContext } from '../services/UserContext';
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();
  const { user, login, isTokenExpired } = useContext(UserContext);
  const { t, i18n } = useTranslation();

  const onSubmit = data => {
    login(
      data.username,
      data.password
    ).then(
      response => { },
      error => {
        const resMessage =
          error.response?.data?.error ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
      }
    );
    setLoading(true);
  };

  return (
    <div className="flex items-center h-screen w-full bg-blue-100">
      <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
        <h1 className="block w-full text-center text-grey-darkest mb-6">{t('Login')}</h1>
        {user && isTokenExpired() && <p className="bg-red-100 p-2 mb-4">Uw sessie is verlopen.</p>}
        <form
          className="mb-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="field-group">
            <label
              className="field-label"
              htmlFor="username">{t('username')}</label>
            <input
              type="text"
              className="field"
              name="username"
              autoComplete="username"
              {...register('username', { required: true })}
            />
          </div>

          <div className="field-group">
            <label
              className="field-label"
              htmlFor="password">{t('password')}</label>
            <input
              type="password"
              className="field"
              name="password"
              autoComplete="current-password"
              {...register('password', { required: true })}
            />
          </div>

          <div className="field-group">
            <button
              type="submit"
              className="basic-button-blue"
              disabled={loading}
            >
              {loading
                ? t('logging in...')
                : t('Login')
              }
            </button>
          </div>
          <p>{t(message)}</p>
        </form>
      </div>
    </div>
  );

};

export default Login;
