import React, { useCallback } from "react";

import { useForm } from "react-hook-form";
import axios from 'axios';
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import ToolService from "../../services/tool.service";
import { serviceCallWrapper } from '../common';

const SiteDetailsDialog = (props) => {
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      name: props.data?.name || '',
      street: props.data?.street || '',
      number: props.data?.number || '',
      postalcode: props.data?.postalcode || '',
      city: props.data?.city || '',
      country: props.data?.country || '',
      latitude: props.data?.latitude || '',
      longitude: props.data?.longitude || '',
      promnumber: props.data?.promnumber || '',
      objecttype: props.data?.objecttype || ''
    }
  });

  const onLatLngLookup = useCallback(() => {
    const number = getValues('number');
    const postalcode = getValues('postalcode');
    const street = getValues('street');
    const city = getValues('city');
    serviceCallWrapper(ToolService.addressLookup(postalcode, number), data => {
      setValue('latitude', data.lat.toFixed(5) || '');
      setValue('longitude', data.lon.toFixed(5) || '');
      if (street === '') {
        setValue('street', data.street || '');
      }
      if (city === '') {
        setValue('city', data.city || '');
      }
      setValue('country', data.country || '');
    });
  }, []);

  return (
    <ModalBase title="Algemeen" onClose={props.onClose} className="max-w-4xl">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <div className="grid grid-cols-3 gap-2  items-start">
          <div className="col-span-2 p-6 grid grid-cols-4 gap-2 items-end">
            <div className="col-span-4">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
                Locatienaam
              </label>
              <input className="basic-input" type="text" {...register('name')}/>
            </div>
            <div className="col-span-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="street">
                Straat
              </label>
              <input className="basic-input" type="text" autoFocus {...register('street')}/>
            </div>
            <div className="">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="number">
                Nummer
              </label>
              <input className="basic-input" type="text" {...register('number')}/>
            </div>
            <div className="">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="postalcode">
                Postcode
              </label>
              <input className="basic-input" type="text" {...register('postalcode')}/>
            </div>
            <div className="col-span-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="city">
                Woonplaats
              </label>
              <input className="basic-input" type="text" {...register('city')}/>
            </div>
            <div className="col-span-4">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="country">
                Land
              </label>
              <input className="basic-input" type="text" {...register('country')}/>
            </div>
            <div className="col-span-1">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="latitude">
                Latitude
              </label>
              <input className="basic-input" type="text" {...register('latitude')}/>
            </div>
            <div className="col-span-1">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="longitude">
                Longitude
              </label>
              <input className="basic-input" type="text" {...register('longitude')}/>
            </div>
            <div className="col-span-2">
            <button onClick={onLatLngLookup} className="w-full basic-button-blue" type="button">
              Aanvullen
            </button>
            </div>
          </div>
          <div className="p-6 grid grid-cols-4 gap-2">
            <div className="col-span-4">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="promnumber">
                Promnummer
              </label>
              <input className="basic-input" type="text" {...register('promnumber')}/>
            </div>
            <div className="col-span-4">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="objecttype">
                Objectsoort
              </label>
              <input className="basic-input" type="text" {...register('objecttype')}/>
            </div>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText="Opslaan"/>
      </form>
    </ModalBase>
  );
};

export default SiteDetailsDialog;
