import React, { useEffect, useState } from "react";
import PartService from "../../../services/part.service";
import { serviceCallWrapper } from '../../common';

const Detail = props => {
  return (
    <p>dummy Detail</p>
  );
};

const Config = props => {
  return (
    <div className="p-6 grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="host">
          Device ID
        </label>
        <input className={`basic-input ${'device_id' in props.errors && `border-red-500`}`} type="text" autoFocus {...props.register('device_id')}/>
      </div>
    </div>
  );
};

const ConfigDisplay = props => {
  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <p className="uppercase text-sm">Device ID:</p>
        <p>{props.config.device_id}</p>
      </div>
    </div>
  );
};

const Icon = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">

    </svg>
  );
};

export default { Detail, Config, ConfigDisplay, Icon };
