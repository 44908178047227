import React, { createContext, useState, useCallback } from 'react';
import axios from "axios";

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const login = (username, password) =>{
    return axios
      .post(API_URL + "login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          const returnedUser = response.data;
          setLocalUser(returnedUser);
        }
        return response.data;
      });
  };

  const isTokenExpired = (token) => {
    if (!user) {
      return true;
    }
    const expiry = (JSON.parse(atob(user.accessToken.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  };

  const setLocalUser = useCallback((newUser) => {
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
  }, []);

  const getLocalUser = useCallback(() => {
    return JSON.parse(localStorage.getItem('user'));
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("user");
    setUser(null);
  }, []);

  return (
    <UserContext.Provider value={{ user, login, logout, setLocalUser, getLocalUser, isTokenExpired }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
