import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api";

class ProfileService {
  login() {
    return axios.get(API_URL + '/login');
  }

  changePassword(data) {
    return axios.post(API_URL + '/changepassword', data, { headers: authHeader() });
  }
}

export default new ProfileService();
