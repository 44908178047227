import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/tools";

class ToolService {
  addressLookup(postalcode, number) {
    return axios.post(API_URL + '/addresslookup', { postalcode: postalcode, number: number }, { headers: authHeader() });
  }
}

export default new ToolService();
