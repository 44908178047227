import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";

import { UserContext } from '../services/UserContext';
import { useTranslation } from "react-i18next";
import ProfileService from '../services/profile.service';
import { serviceCallWrapper } from './common';
import { useNavigate } from "react-router-dom";

const ChangePassword = (props) => {
  const { setLocalUser, user } = useContext(UserContext);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: user.username
    }
  });
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = dataIn => {
    serviceCallWrapper(ProfileService.changePassword(dataIn), data => {
      setLocalUser(data);
      navigate("/");
    }, error => {
      setMessage(error.response.data.error);
    });
  };

  return (
    <div className="flex items-center h-screen w-full bg-blue-100">
      <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
        <h1 className="block w-full text-center text-grey-darkest mb-6">{t('Change password')}</h1>
        <form
          className="mb-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="field-group">
            <label
              className="field-label"
              htmlFor="username">{t('username')}</label>
            <input
              type="text"
              disabled
              className="field"
              name="username"
              autoComplete="username"
              {...register('username', { required: true })}
            />
          </div>

          <div className="field-group">
            <label
              className="field-label"
              htmlFor="oldpassword">{t('old password')}</label>
            <input
              type="password"
              className="field"
              name="oldpassword"
              autoComplete="current-password"
              {...register('oldpassword', { required: true })}
            />
          </div>

          <div className="field-group">
            <label
              className="field-label"
              htmlFor="newpassword">{t('new password')}</label>
            <input
              type="password"
              className="field"
              name="newpassword"
              autoComplete="new-password"
              {...register('newpassword', { required: true })}
            />
          </div>

          <div className="field-group">
            <button
              type="submit"
              className="basic-button-blue"
              disabled={loading}
            >
              {loading
                ? t('Changing...')
                : t('Change')
              }
            </button>
          </div>
          <p>{t(message)}</p>
        </form>
      </div>
    </div>
  );

};

export default ChangePassword;
