import React, { useState } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import PartService from "../../services/part.service";
import { useNavigate } from "react-router-dom";
import { DialogButtonBox } from "../common";
import { serviceCallWrapper } from '../common';
import { partTypes } from '../equipment/parts/parts';

const EditPartDialog = (props) => {
  const [ errorMessage, setErrorMessage ] = useState();
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: props.data?.name,
      type: props.data?.type
    }
  });

  return (
    <ModalBase title="Algemeen" className="w-1/4" onClose={props.onClose} >
      <form onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          {errorMessage && <div className="col-span-4 bg-red-200 p-2">
            {errorMessage}
          </div>}
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Naam
            </label>
            <input className={`basic-input ${'name' in errors && `border-red-500`}`} type="text" autoFocus {...register('name', { required: true })}/>
            {errors.name && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-role">
              Type
            </label>
            <select className="basic-select" {...register('type', { required: true })}>
            {Object.keys(partTypes).map((key) =>
              <option value={key}>{partTypes[key].name}</option>)}
            </select>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText={props.data ? 'Opslaan' : 'Aanmaken'}/>
      </form>
    </ModalBase>
  );
};

export default EditPartDialog;
