import React, { useState } from "react";

import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const EquipmentDeleteDialog = (props) => {
  const [ confirmationText, setConfirmationText ] = useState('');

  const handleChange = event => {
    setConfirmationText(event.target.value);
  };

  const onDelete = () => {
    props.onDelete();
  };
  
  return (
    <ModalBase onClose={props.onClose} className="w-1/4">
      <div className="p-6">
        <p>Weet je zeker dat je dit apparaat wilt verwijderen?</p>
        {props.parts.length > 0 && <p><br/>De volgende hardware wordt vrijgemeld:</p>}
        {props.parts.map((part) =>
          <p>{part.name}</p>
        )}
        {props.reservations.length > 0 && <p><br/>Er worden {props.reservations.length} reserveringen stopgezet</p>}
        {(props.parts.length > 0 || props.reservations.length > 0) &&
          <>
          <br/>
          <p>Type in het onderstaande veld de tekst 'verwijderen' om te bevestigen.</p>
          <input
            autoComplete="off"
            type="text"
            className="border border-black pl-2 w-full mt-4"
            name="s"
            value={confirmationText}
            onChange={handleChange}
          />
        </>
        }
      </div>
      {(props.parts.length > 0 || props.reservations.length > 0)
        ? <DialogButtonBox onCancel={props.onClose} onDelete={onDelete} deleteDisabled={confirmationText !== 'verwijderen'}/>
        : <DialogButtonBox onCancel={props.onClose} onDelete={onDelete}/>
      }
    </ModalBase>
  );
};

export default EquipmentDeleteDialog;
