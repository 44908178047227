import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/parts";

class PartService {
  getAll() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getAllFree(data) {
    return axios.post(API_URL + '/free', data, { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL, data, { headers: authHeader() });
  }

  delete(Id) {
    return axios.delete(API_URL + '/' + Id, { headers: authHeader() });
  }

  get(Id) {
    return axios.get(API_URL + '/' + Id, { headers: authHeader() });
  }

  getImage(Id) {
    return axios.get(API_URL + '/' + Id + '/image', { headers: authHeader(), responseType: 'blob' });
  }

  update(Id, data) {
    return axios.post(API_URL + '/' + Id, data, { headers: authHeader() });
  }

  findByName(name) {
    return axios.post(API_URL + '/find', { name: name }, { headers: authHeader() });
  }
}

export default new PartService();
