import React, { useState } from "react";

import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const DeleteCustomerDialog = (props) => {
  const [ confirmationText, setConfirmationText ] = useState('');

  const handleChange = event => {
    setConfirmationText(event.target.value);
  };

  return (
    <ModalBase onClose={props.onClose} className="w-1/4">
      <div className="p-6">
        <p>Weet je zeker dat je deze klant en alle bijbehorende locaties wilt verwijderen?</p>
        <br/>
        <p>Nog actieve reserveringen blijven actief.</p>
        <br/>
        <p>Type in het onderstaande veld de tekst 'verwijderen' om te bevestigen.</p>
        <input
          autoComplete="off"
          type="text"
          className="border pl-2"
          name="s"
          value={confirmationText}
          onChange={handleChange}
        />
      </div>
      <DialogButtonBox onCancel={props.onClose} onDelete={props.onDelete} deleteDisabled={confirmationText !== 'verwijderen'}/>
    </ModalBase>
  );
};

export default DeleteCustomerDialog;
