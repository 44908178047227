import React from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const AddressDialog = (props) => {
  const { register, handleSubmit } = useForm({ defaultValues: props.data });

  return (
    <ModalBase title="Aanpassen" onClose={props.onClose} className="w-1/4">
      <form className="" onSubmit={handleSubmit(props.onSubmit)} autocomplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
              Klantnaam
            </label>
            <input className="basic-input" type="text" autoFocus {...register('name')}/>
          </div>
          <div className="col-span-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="street">
              Straat
            </label>
            <input className="basic-input" type="text" autoFocus {...register('street')}/>
          </div>
          <div className="">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="number">
              Nummer
            </label>
            <input className="basic-input" type="text" {...register('number')}/>
          </div>
          <div className="">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="postalcode">
              Postcode
            </label>
            <input className="basic-input" type="text" {...register('postalcode')}/>
          </div>
          <div className="col-span-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="city">
              Woonplaats
            </label>
            <input className="basic-input" type="text" {...register('city')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="country">
              Land
            </label>
            <input className="basic-input" type="text" {...register('country')}/>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="memo">
              Memo
            </label>
            <textarea className="basic-input" id="grid-memo" {...register('memo')}/>
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit/>
      </form>
    </ModalBase>
  );
};

export default AddressDialog;
