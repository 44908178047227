import React, { useState } from "react";

import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";

const DeletePartDialog = (props) => {
  const [ confirmationText, setConfirmationText ] = useState('');

  const handleChange = event => {
    setConfirmationText(event.target.value);
  };

  const onDelete = () => {
    props.onDelete();
  };

  return (
    <ModalBase onClose={props.onClose} className="w-1/4">
      <div className="p-6">
        <p>Weet je zeker dat je dit onderdeel wilt verwijderen?</p>
        {props.part.equipment && <>
          <p><br/>Het is momenteel in gebruik door:</p>
          <p>{props.part.equipment.name}</p>
          </>
        }
      </div>
      <DialogButtonBox onCancel={props.onClose} onDelete={onDelete}/>
    </ModalBase>
  );
};

export default DeletePartDialog;
