import React, { useState } from "react";

import { IconPlus, IconBin } from "./icons.component";
import { IconButtonPlus, IconButtonBin } from "./common.js";


const PageBase = (props) => {
  const handleKeyDown = event => {
    if (props.onSearchEnter && event.key === 'Enter') {
      props.onSearchEnter();
    }
  };

  const [ searchText, setSearchText ] = useState('');

  const handleChange = event => {
    setSearchText(event.target.value);
    if (props.onSearch) {
      props.onSearch(event.target.value);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex-1 flex flex-col md:flex-row gap-1 md:gap-2">
        <div className="flex flex-1">
          <span className="flex-1 text-3xl font-bold text-gray-900 px-1 md:px-2">{props.header}</span>
          {props.onAdd && <IconButtonPlus onClick={props.onAdd}/>}
          {props.onBin && <IconButtonBin onClick={props.onBin}/>}
        </div>
        <div className="flex gap-1 md:gap-2">
          {props.onSearch &&
          <input
            autoFocus
            autoComplete="off"
            type="text"
            className="flex-1 md:flex-0 appearance-none block text-gray-700 border rounded p-2 leading-tight focus:outline-none"
            placeholder={props.searchPlaceholder}
            name="s"
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />}
        </div>
      </div>
      <div className="pt-1 md:pt-2 flex-1">
        {props.children}
      </div>
    </div>
  );
};

export default PageBase;
