import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";

import UserService from "../../services/user.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { serviceCallWrapper } from '../common';
import { useNavigate, useParams } from "react-router-dom";
import { IconButtonPencil, IconButtonBin, ButtonBox, HeaderWithButtons, IconButtonPlus } from "../common.js";
import SiteService from "../../services/site.service";
import CustomerService from "../../services/customer.service";
import UserDetailsDialog from './details.dialog';
import { UserContext } from '../../services/UserContext';
import ConfirmDialog from "../confirmdialog.component";

const UserDetail = (props) => {
  const { userId } = useParams();
  const [ user, setUser ] = useState({});
  const [ isUserDelete, setUserDelete ] = useState(false);
  const [ isUserEdit, setUserEdit ] = useState(false);
  const [ customers, setCustomers ] = useState([]);
  const [ sites, setSites ] = useState([]);
  const { setLocalUser, getLocalUser } = useContext(UserContext);
  const navigate = useNavigate();

  const parseResponse = data => {
    setUser(data.user);
    setCustomers(data.customers);
    setSites(data.sites);
  };

  useEffect(() => {
    serviceCallWrapper(UserService.get(userId), data => {
      parseResponse(data);
    });
  }, [ userId ]);

  const onSubmitDetails = newUser => {
    setUser(newUser);
    const localUser = getLocalUser();
    if (localUser._id === newUser._id) {
      localUser.username = newUser.username;
      setLocalUser(localUser);
    }
    setUserEdit(false);
  };

  const handleCustomersSearch = text => {
    /*const customerIds = user.customers.map(customer => customer._id);
    serviceCallWrapper(CustomerService.findByName(text), data => {
      const filtered = data.customers.filter(el => customerIds.indexOf(el._id) === -1);
      setCustomers(filtered);
    });*/
  };

  const handleSitesSearch = text => {
    /*const siteIds = user.sites.map(site => site._id);
    serviceCallWrapper(SiteService.findByName(text), data => {
      const filtered = data.sites.filter(el => siteIds.indexOf(el._id) === -1);
      setSites(filtered);
    });*/
  };

  const addCustomer = customerId => {
    user.customers = [ ... user.customers, customerId ];
    user.customers = [ ...new Set(user.customers) ];
    serviceCallWrapper(UserService.update(userId, user), data => {
      parseResponse(data);
    });
  };

  const addSite = siteId => {
    user.sites = [ ... user.sites, siteId ];
    user.sites = [ ...new Set(user.sites) ];
    serviceCallWrapper(UserService.update(userId, user), data => {
      parseResponse(data);
    });
  };

  const deleteCustomer = customerId => {
    const newCustomers = user.customers.filter(el => el !== customerId);
    const data = { customers : newCustomers };
    serviceCallWrapper(UserService.update(userId, data), data => {
      parseResponse(data);
    });
  };

  const deleteSite = siteId => {
    const newSites = user.sites.filter(el => el !== siteId);
    const data = { sites : newSites };
    serviceCallWrapper(UserService.update(userId, data), data => {
      parseResponse(data);
    });
  };

  const onDeleteUser = useCallback(() => {
    serviceCallWrapper(UserService.delete(userId), data => {
      navigate("/users");
    });
  }, [ userId, navigate ]);

  return (
    <>
    {isUserEdit && <UserDetailsDialog data={user} onClose={() => setUserEdit(false)} onSubmit={onSubmitDetails}/>}
    {isUserDelete &&  <ConfirmDialog question={"Weet je zeker dat je deze gebruiker wilt verwijderen?"} onConfirm={onDeleteUser} onCancel={() => setUserDelete(false)}/>}
    <PageBase
      header={user.username}
      onBin={() => setUserDelete(true)}>
      <div className="grid grid-cols-3 gap-2 items-stretch">
        <div className="block rounded-lg border shadow relative p-2 group">
          <span className="font-semibold">Algemeen</span>
          <div className="">
            <p className="uppercase text-sm">Type:</p><p className="pl-2">{user.isAdmin ? 'Administrator' : 'Gebruiker'}</p>
          </div>
          <ButtonBox>
            <IconButtonPencil onClick={() => setUserEdit(true)}/>
          </ButtonBox>
        </div>

        <div className="h-96 col-span-2 rounded-lg border shadow">

        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 mt-2">
        <div className="rounded-lg border shadow p-2">
          <div className="grid gap-2">
          {customers.map((customer, index) =>
            <div key={index} className="flex justify-between">
              <p>{customer.name}</p>
              {customer.isAdded && <IconButtonBin onClick={() => deleteCustomer(customer._id)}/>}
              {!customer.isAdded && <IconButtonPlus onClick={() => addCustomer(customer._id)}/>}
            </div>)}
          </div>
        </div>
        <div className="rounded-lg border shadow p-2">
          <div className="grid gap-2">
          {sites.map((site, index) =>
            <div key={index} className="flex justify-between">
              <p className="py-2">{site.name}</p>
              {(!site.isFixed && site.isDynamic) && <IconButtonBin onClick={() => deleteSite(site._id)}/>}
              {(!site.isFixed && !site.isDynamic) && <IconButtonPlus onClick={() => addSite(site._id)}/>}
            </div>)}
          </div>
        </div>
      </div>

    </PageBase>
    </>
  );
};

export default UserDetail;
