import React, { useState, useEffect } from "react";

import PartService from "../../services/part.service";
import PageBase from "../pagebase.component";
import { useNavigate } from "react-router-dom";
import { serviceCallWrapper } from '../common';
import EditPartDialog from "./editpart.dialog";
import { useContext } from "react";
import { UserContext } from '../../services/UserContext';
import { partTypes } from '../equipment/parts/parts';

const issueColor = state => {
  const colors = {
    'new': 'bg-red-500',
    'acknowledged': 'bg-red-200',
    'confirmed': 'bg-orange-200',
    'assigned': 'bg-yellow-200',
    'resolved': 'bg-green-200',
    'closed': ''
  };
  return colors[state];
};

const Parts = (props) => {
  const [ parts, setParts ] = useState([]);
  const navigate = useNavigate();
  const [ showAddPart, setShowAddPart ] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    serviceCallWrapper(PartService.getAll(), data => {
      setParts(data.parts);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(PartService.findByName(text), data => {
      setParts(data.parts);
    });
  };

  const handleSearchEnter = () => {
    if (parts.length === 1) {
      navigate("/parts/" + parts[0]._id);
    }
  };

  const onAddPart = data => {
    serviceCallWrapper(PartService.add(data), data => {
      navigate("/parts/" + data.part._id);
    });
  };

  return (
    <PageBase
        header="Onderdelen"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search parts"
        onAdd={user.isAdmin ? () => setShowAddPart(true) : null}
      >
      {showAddPart && <EditPartDialog onClose={() => setShowAddPart(false)} onSubmit={onAddPart} />}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-1 md:gap-2">
      {parts.map((part, i) =>
        <button className={"text-left w-full border p-2 rounded-md shadow grid grid-cols-1 md:grid-cols-3 " + issueColor(part.issue.at(0)?.state)} key={i} onClick={() => navigate("/parts/"+part._id)}>
          <p>{part.name}</p>
          <p>{partTypes[part.type]?.name || 'invalid'}</p>
          <p>{part.equipment && part.equipment.name}</p>
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default Parts;
