import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window.location.protocol + "//" + window.location.hostname + "/api/issues";

class IssueService {
  update(Id, data) {
    return axios.post(API_URL + '/' + Id, data, { headers: authHeader() });
  }

  getCounts() {
    return axios.get(API_URL + '/counts', { headers: authHeader() });
  }

}

export default new IssueService();
