import React, { useState } from "react";

import { useForm } from "react-hook-form";
import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import { serviceCallWrapper } from '../common';

const IssueDialog = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      state: props.data?.state
    }
  });

  return (
    <ModalBase title="Algemeen" className="w-[48rem]" onClose={props.onClose} >
      <form onSubmit={handleSubmit(props.onSubmit)} autoComplete="off">
        <div className="p-6 grid grid-cols-4 gap-2">
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="memo">
              Message
            </label>
            <span className="basic-select">{props.data.message}</span>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="state">
              State
            </label>
            <select className="basic-select" id="state" {...register('state')}>
              <option value="new">New</option>)}
              <option value="acknowledged">Acknowledged</option>)}
              <option value="confirmed">Confirmed</option>)}
              <option value="assigned">Assigned</option>)}
              <option value="resolved">Resolved</option>)}
              <option value="closed">Closed</option>)}
            </select>
          </div>
          <div className="col-span-4">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="comment">
              Comment
            </label>
            <textarea className="basic-input" id="comment" {...register('comment')}/>
          </div>
          <h1>History</h1>
          <div className="col-span-4 grid grid-cols-8">
            <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">State</p>
            <p className="col-span-5 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Comment</p>
            <p className="col-span-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Date</p>
          </div>
          <div className="col-span-4 grid grid-cols-8 overflow-auto max-h-40">
          {props.data.history.map((entry, index) =>
            <>
            <p>{entry.state}</p>
            <p className="col-span-5">{entry.comment}</p>
            <p className="col-span-2">{entry.changeDate && new Date(entry.changeDate).toLocaleString('nl-NL')}</p>
            </>
          )}
          </div>
        </div>
        <DialogButtonBox onCancel={props.onClose} onSubmit submitText="opslaan"/>
      </form>
    </ModalBase>
  );
};

export default IssueDialog;
