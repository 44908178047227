import React, { useContext, useEffect } from "react";
import "./App.css";

import { UserContext } from './services/UserContext';

import AuthApp from "./AuthApp";
import Login from "./components/login.component";

import EventBus from "./common/EventBus";
import ChangePassword from "./components/changepassword.component";

const App = (props) => {
  const { user, logout, isTokenExpired } = useContext(UserContext);

  useEffect(() => {
    EventBus.on("logout", () => {
      logout();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logout]);
  return (user && !isTokenExpired()) ? user.forcePasswordReset ? <ChangePassword /> : <AuthApp /> : <Login />;
};

export default App;
