import React from 'react';
import { Routes, Route } from "react-router-dom";
import UsersList from "./list.component";
import UserDetail from "./detail.component";

function Users(props) {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<UsersList />} />
        <Route path=":userId" element={<UserDetail />} />
      </Routes>
    </React.Fragment>
  );
}

export default Users;
