import Rut955 from './rut955';
import MobotixM16B from './mobotixm16b';
import HikvisionDS from './hikvisionds';
import ProvisionBMHThermal3 from './provisionbmhthermal3';
import Dummy from './dummy';
import AjaxHub from './ajaxhub';
import { IconRUT, IconCam } from "../../icons.component";

const partTypes = {
  Rutx11: {
    name: 'RUT X11',
    part: Dummy
  },
  Rut955: {
    name: 'RUT 955',
    part: Rut955
  },
  MobotixM16B: {
    name: 'Mobotix M16B',
    part: MobotixM16B
  },
  HikVisionDS: {
    name: 'HikVision DS',
    part: HikvisionDS
  },
  ProvisionBMHThermal3: {
    name: 'Provision BMH Thermal 3',
    part: ProvisionBMHThermal3
  },
  AjaxPanel: {
    name: 'Ajax Panel',
    part: Dummy
  },
  RiscoPanel: {
    name: 'Risco Panel',
    part: Dummy
  },
  AjaxSocketF: {
    name: 'Ajax Socket (Type F)',
    part: Dummy
  },
  AjaxWallSwitch: {
    name: 'Ajax WallSwitch',
    part: Dummy
  },
  AjaxRelay: {
    name: 'Ajax Relay',
    part: Dummy
  },
  AjaxHub22G: {
    name: 'Ajax Hub 2 (2G)',
    part: AjaxHub
  },
  AjaxHub24G: {
    name: 'Ajax Hub 2 (4G)',
    part: AjaxHub
  },
  AjaxHubPlus: {
    name: 'Ajax Hub Plus',
    part: AjaxHub
  },
  AjaxHub: {
    name: 'Ajax Hub',
    part: AjaxHub
  },
  AjaxHub2Plus: {
    name: 'Ajax Hub 2 Plus',
    part: AjaxHub
  }
};

const PartFactory = type => {
  //return partTypes[type]?.part || Dummy;
  const part = partTypes[type];
  if (part) {
    return part.part;
  }
  return Dummy;
};

const PartDetail = props => {
  const Part = PartFactory(props.part.type);
  return <Part.Detail part={props.part} equipmentId={props.equipmentId}/>;
};

const PartConfig = props => {
  const Part = PartFactory(props.part.type);
  return <Part.Config config={props.part.config} register={props.register} errors={props.errors}/>;
};

const PartConfigDisplay = props => {
  const Part = PartFactory(props.part.type);
  return <Part.ConfigDisplay config={props.part.config || {}}/>;
};

const PartIcon = props => {
  const Part = PartFactory(props.part.type);
  return <Part.Icon/>;
};

/*[
  {
    name: 'RUT X11',
    type: 'Rutx11'
  }, {
    name: 'RUT 955',
    type: 'Rut955'
  }, {
    name: 'Mobotix M16B',
    type: 'MobotixM16B'
  }, {
    name: 'Ajax Panel',
    type: 'AjaxPanel'
  }, {
    name: 'Risco Panel',
    type: 'RiscoPanel'
  }
];*/

export { PartDetail, partTypes, PartIcon, PartConfig, PartConfigDisplay };
