import React, { useEffect, useState } from "react";
import PartService from "../../../services/part.service";
import { serviceCallWrapper } from '../../common';

const Detail = props => {
  const part = props.part;
  const [src, setSrc ] = useState("");
  const [ isLoading, setLoading ] = useState(false);

  const updateImage = () => {
    serviceCallWrapper(PartService.getImage(part._id), data => {
      setSrc(URL.createObjectURL(data));
      setLoading(false);
    }, data => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    updateImage();
  }, [part]);

  return (
    <div>
      {isLoading ? <p>Loading...</p> : <img alt="Image could not be loaded" src={src}/>}
    </div>
  );
};

const Config = props => {
  return (
    <div className="p-6 grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="host">
          Host
        </label>
        <input className={`basic-input ${'host' in props.errors && `border-red-500`}`} type="text" autoFocus {...props.register('host')}/>
        {props.errors.host && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
      </div>
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="port">
          Port
        </label>
        <input className={`basic-input ${'port' in props.errors && `border-red-500`}`} type="text" autoFocus {...props.register('port')}/>
        {props.errors.port && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
      </div>
    </div>
  );
};

const ConfigDisplay = props => {
  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <p className="uppercase text-sm">Host:</p>
        <p>{props.config.host}</p>
      </div>
      <div className="col-span-2">
        <p className="uppercase text-sm">Port:</p>
        <p>{props.config.port}</p>
      </div>
    </div>
  );
};

const Icon = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M 6,8 l 14,-7 l 4,8 l-14,7 l -4,-8" />
        <path d="M 17,13 l 3,6 l 3,-3 v 6 l -3,-3" />
        <path d="M 8,11 l -2,4 l -2,-4 l 2,0" />
      </g>
    </svg>
  );
};

export default { Detail, Config, ConfigDisplay, Icon };
