import React from "react";

const IconMap = () => {
  return (
    <svg className="w-8 h-8 inline-block mr-2" viewBox="0 0 771.13 701.83" version="1.1">
      <defs>
        <filter id="filter4037" height="1.2421" width="1.0798" y="-.12104" x="-.039886" colorInterpolationFilters="sRGB">
          <feGaussianBlur id="feGaussianBlur4039" stdDeviation="11.868751"/>
        </filter>
      </defs>
      <g transform="translate(-68.721 -58.594)">
        <path filter="url(#filter4037)" d="m169.86 507.87c-20.785 77.131-51.799 144.5-72.654 221.61 2.1015 4.6742 8.8667 1.0502 12.719 2.1875 233.25-0.083 466.56 0.1667 699.78-0.125 4.2968-2.5855-1.1212-8.655-1.0077-12.491-20.23-74.16-48.44-142.55-68.69-216.7-196.02 0-373.71 4.0362-569.73 4.0362l-0.27 0.96z"/>
        <path fill="#dcd3ba" d="m746.45 498.58 62.933 230.64c0.0643 0.2358-0.23656 0.4411-0.53041 0.4411h-708.94c-0.29385 0-0.59221-0.2046-0.53041-0.4411l62.067-230.64z"/>
        <path opacity=".4856" fill="#89d375" d="m726.73 727.45c0-1.1136-3.3217-4.2151-5.067-4.7311-2.3188-0.6855-9.2187-4.8201-9.2187-5.5241 0-0.3098-0.40179-0.5771-0.89286-0.5938-2.6563-0.091-10.236-9.2779-11.922-14.45-0.64973-1.9935-1.5989-3.0627-3.6462-4.1071-1.5223-0.7766-3.2689-1.4121-3.8812-1.4121-0.61236 0-2.7948-0.8905-4.8499-1.979s-5.6651-2.5678-8.0222-3.2874c-6.7148-2.0498-10.022-3.9058-14.701-8.2492-3.3438-3.1039-4.6508-4.927-5.6893-7.9356-0.74155-2.1483-1.3588-4.4202-1.3716-5.0487-0.0129-0.6286-0.94749-2.4186-2.077-3.9779s-2.2762-3.5361-2.5481-4.3929c-0.27195-0.8568-0.79421-1.5578-1.1606-1.5578-0.36637 0-0.66615-0.631-0.66615-1.4021 0-1.9039-6.7557-8.343-10.714-10.212-1.7679-0.8347-3.4713-1.938-3.7855-2.4518-0.31417-0.5137-1.7606-0.9343-3.2143-0.9345-4.633-0.0009-20.857-4.9085-20.857-6.309 0-0.3169-0.95919-1.6505-2.1315-2.9636s-2.3386-3.0398-2.5917-3.8372c-0.25309-0.7974-1.7046-3.4831-3.2256-5.9681-2.6817-4.3813-2.7658-4.718-2.7762-11.113-0.01-6.0881-0.73063-9.7693-2.5034-12.786-0.3851-0.6554-3.2716-2.4385-6.4145-3.9625-3.1429-1.5239-6.6229-3.3712-7.7334-4.105-1.6882-1.1156-4.0881-1.4192-14.643-1.8523-13.358-0.5482-19.644-1.8523-23.824-4.9423-1.0759-0.7955-2.2261-1.4463-2.556-1.4463-0.32984 0-1.0065-1.125-1.5037-2.5s-1.3654-2.5-1.9292-2.5c-0.56386 0-1.0252-0.3147-1.0252-0.6993 0-0.9196-7.8027-8.5865-8.7386-8.5865-0.61877 0-8.4709-3.756-13.047-6.2409-0.78572-0.4267-3.3571-1.1475-5.7143-1.6018-4.7721-0.9197-10.318-3.3679-10.867-4.7968-0.19667-0.5126-0.72498-0.9319-1.174-0.9319-1.4014 0-4.496-4.5161-5.0237-7.3312-0.27717-1.4785-0.52833-7.8783-0.55812-14.222-0.0534-11.366-0.0832-11.579-2.0572-14.692-1.1017-1.7372-3.3923-4.5684-5.0902-6.2916s-4.6155-4.7108-6.4835-6.6392-4.8349-4.8322-6.5931-6.4529l-2.7909-3.8435h275l31.093 115.19c16.88 62.534 29.839 114.01 29.861 114.11 0.022 0.098-18.121 0.1786-40.317 0.1786s-40.357-0.2745-40.357-0.61z"/>
        <path fill="#f7fd9f" d="m589.45 498.58h45l9.933 231.08h-65z"/>
        <g transform="translate(105.02 -618.78)" fill="#fff">
          <path d="m676.43 1242.4 10 40h-590l10-35z"/>
          <path d="m116.43 1117.4h40l-36.067 165.08h-50z"/>
        </g>
        <path fill="none" d="m746.45 498.58 62.933 230.64c0.0643 0.2358-0.23656 0.4411-0.53041 0.4411h-708.94c-0.29385 0-0.59221-0.2046-0.53041-0.4411l62.067-230.64z"/>
        <path opacity=".8" d="m746.45 498.58 62.933 230.64c0.0643 0.2358-0.23656 0.4411-0.53041 0.4411h-708.94c-0.29385 0-0.59221-0.2046-0.53041-0.4411l62.067-230.64z" stroke="#000" strokeWidth="10" fill="none"/>
        <path stroke="#000" strokeWidth="10.397" fill="#e52626" d="m452.08 63.785c-99.484 0-179.81 80.41-179.81 179.59 0 31.186 7.9688 60.528 21.938 86.094l157.88 264.31 157.88-264.31c13.969-25.566 21.938-54.908 21.938-86.094 0-99.184-80.328-179.59-179.81-179.59zm-2 104.37c0.6674-0.0169 1.3285 0 2 0 42.975 0 77.812 34.838 77.812 77.813s-34.838 77.812-77.812 77.812c-42.975 0-77.812-34.838-77.812-77.812 0-42.303 33.766-76.747 75.812-77.813z"/>
      </g>
    </svg>
  );
};

const IconPerson = () => {
  return (
    <svg className="w-8 h-8 mr-2 inline-block" viewBox="0 0 99.013 122.5" version="1.1">
      <g transform="translate(-719.06 -548.25)">
        <path d="m767.44 599.51c35 0 45 10 48.325 30.107 3.3203 20.107 1.6748 39.893-48.325 39.893s-50-20-45-40 10-30 45-30" stroke="#1e4a85" strokeWidth="2.5" fill="#2e5e99"/>
        <path d="m757.44 599.51h25l-15 55z" stroke="#1e4a85" strokeWidth="2.5" fill="#ffffff"/>
        <path d="m752.44 594.51 10 10-5 5z" stroke="#1e4a85" strokeWidth="2.5" fill="#6161ff"/>
        <path d="m777.44 604.51 10-10-5 15z" stroke="#1e4a85" strokeWidth="2.5" fill="#6161ff"/>
        <path d="m742.44 629.51c-5.3418 9.0674-7.9443 19.487-7.5 30" stroke="#1e4a85" strokeWidth="2.5" fill="none"/>
        <path d="m799.94 659.51c0.9375-10.264-0.786-20.596-5-30" stroke="#1e4a85" strokeWidth="2.5" fill="none"/>
        <path d="m797.44 577.01c0 15.186-12.314 27.5-27.5 27.5-15.19 0-27.5-12.314-27.5-27.5 0-15.19 12.31-27.5 27.5-27.5 15.186 0 27.5 12.31 27.5 27.5" stroke="#ffb068" strokeWidth="2.5" fill="#ffd9b5"/>
      </g>
    </svg>
  );
};

const IconReload = () => {
  return (
    <svg className="h-6 w-6" viewBox="0 0 359 337" fill="#FFF" stroke="none">
      <g  transform="translate(-57.554285,-67.639114)">
      	<path d="M408.84,164.345c-9.88,0.022-19.756,0.049-29.636,0.075
      		c-22.217-49.815-65.828-85.797-120.912-94.428c-61.374-9.614-120.115,18.889-155.14,69.036
      		c-15.557,22.269,21.005,43.121,36.431,21.035c48.039-68.774,150.608-62.559,192.76,4.473c-9.125,0.022-18.253,0.045-27.377,0.067
      		c-2.554,0.004-4.368,1.144-5.49,2.745c-1.832,1.967-2.532,4.839-0.61,7.816c17.378,26.966,34.759,53.936,52.141,80.902
      		c2.921,4.532,9.226,4.495,12.12-0.045c17.243-27.052,34.49-54.104,51.733-81.16C417.714,170.388,413.956,164.334,408.84,164.345z"
      		/>
      	<path d="M334.479,312.395c-47.665,68.251-149.037,62.657-191.784-2.909
      		c8.747-0.022,17.494-0.045,26.241-0.067c2.547-0.007,4.357-1.137,5.482-2.734c1.836-1.963,2.539-4.843,0.617-7.827
      		c-17.374-26.966-34.756-53.94-52.133-80.902c-2.917-4.532-9.226-4.495-12.12,0.045c-17.243,27.052-34.494,54.104-51.741,81.16
      		c-2.853,4.476,0.905,10.531,6.021,10.519c10.187-0.026,20.369-0.052,30.556-0.079c22.434,48.996,65.663,84.331,120.164,92.869
      		c61.374,9.614,120.115-18.889,155.132-69.036C386.466,311.157,349.901,290.309,334.479,312.395z"/>
      </g>
    </svg>
  );
};

const IconPencil = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
    </svg>
  );
};

const IconBin = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
  );
};

const IconPlus = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M2 12 h 20 m -10 -10 v 20" />
    </svg>
  );
};

const IconCollapsed = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M7,2 l 10,10 l -10,10" />
    </svg>
  );
};

const IconExpanded = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M2,7 l 10,10 l 10,-10" />
    </svg>
  );
};

export {
  IconMap,
  IconPerson,
  IconReload,
  IconPencil,
  IconBin,
  IconPlus,
  IconCollapsed,
  IconExpanded
};
